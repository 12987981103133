@import './scaffolding-mixin';

$primary-font-family: InspireTWDC, Arial, sans-serif;
$font-family-base: $primary-font-family;
$font-family-light: $primary-font-family;
$font-family-heavy: $primary-font-family;
$font-family-black: $primary-font-family;
$font-family-roman: $primary-font-family;

@mixin type-base() {
    font-family: $primary-font-family;
}

@mixin light() {
    font-family: $font-family-light;
}

@mixin roman() {
    font-family: $font-family-roman;
}

@mixin heavy() {
    font-family: $font-family-heavy;
}

@mixin black() {
    font-family: $font-family-black;
}

@mixin oblique() {
    font-style: italic;
}

/* Hyperion responsive font sizes https://hyperion.disney.com/typography/ */
@mixin t1() {
    font-size: 36px;
    line-height: 54px;
    @include md {
        font-size: 40px;
        line-height: 60px;
    }
    @include lg {
        font-size: 50px;
        line-height: 75px;
    }
}

@mixin t2() {
    font-size: 28px;
    line-height: 42px;
    @include md {
        font-size: 36px;
        line-height: 54px;
    }
    @include lg {
        font-size: 40px;
        line-height: 60px;
    }
}

@mixin t3() {
    font-size: 24px;
    line-height: 36px;
    @include md {
        font-size: 28px;
        line-height: 42px;
    }
    @include lg {
        font-size: 36px;
        line-height: 54px;
    }
}

@mixin t4() {
    font-size: 24px;
    line-height: 36px;
    @include lg {
        font-size: 28px;
        line-height: 42px;
    }
}

@mixin t5() {
    font-size: 24px;
    line-height: 36px;
}

@mixin t6() {
    font-size: 20px;
    line-height: 30px;
}

@mixin t7() {
    font-size: 18px;
    line-height: 27px;
}

@mixin t8() {
    font-size: 16px;
    line-height: 24px;
}

@mixin t9() {
    font-size: 14px;
    line-height: 21px;
}

@mixin t10() {
    font-size: 12px;
    line-height: 18px;
}

body :host-context {
    .dialog {
        #wrapper {
            border: #e3ecf3 2px solid;
        }
    }
}

strong.modal {
    display: block;
    margin: 16px 0;
}
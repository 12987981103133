@import './mixins/typography-mixin';
@import './mixins/scaffolding-mixin';

.type-base {
    @include type-base;
}

.t1 {
    @include t1;
}

.t2 {
    @include t1;
}

.t3 {
    @include t3;
}

.t4 {
    @include t4;
}

.t5 {
    @include t5;
}

.t6 {
    @include t6;
}

.t7 {
    @include t7;
}

.t8 {
    @include t8;
}

.t9 {
    @include t9;
}

.t10 {
    @include t10;
}

.heavy {
    @include heavy;
}

.black {
    @include black;
}

.oblique {
    @include oblique;
}

body {
    @include type-base;
    @include t8;
    color: var(--primary-text-theme);
    --font-family-base-theme: InspireTWDC, Arial, sans-serif;
    --font-family-light-theme: InspireTWDC, Arial, sans-serif;
    --font-family-heavy-theme: InspireTWDC, Arial, sans-serif;
    --font-family-black-theme: InspireTWDC, Arial, sans-serif;
    --font-family-medium-theme: InspireTWDC, Arial, sans-serif;
    --font-family-roman-theme: InspireTWDC, Arial, sans-serif;
    --font-family-book-theme: InspireTWDC, Arial, sans-serif;
}

h1, .header1 {
    @include t2;
    @include heavy;
}

h2, .header2 {
    @include t3;
    @include heavy;
}

h3, .header3 {
    @include t4;
    @include heavy;
}

h4, .header4 {
    @include t5;
    @include heavy;
}

h5, .header5 {
    @include t6;
    @include heavy;
}

/* TODO: This is a massive update to the css rules on the page, 
affecting ALL <p> tags regardless of location and breaking the new 
side navigation.  This CSS rule needs to moved to a more targeted 
CSS selector / box model. ADS - 2024.09.13  
* Added .magicbands rule below.  * Leaving this comment in place 
for training + review with fellow web team members.
p {
    margin: 0 0 1.5em;
}
*/

.magicbands p {
    margin: 0 0 1.5em;
}

ul, ol {
    margin: 0 1.5em 1.5em 0;
    padding-left: 3.333em;
}

ul {
    list-style-type: disc;
}

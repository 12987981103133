/* Hyperion Breakpoints https://hyperion.disney.com/breakpoints-grids/ */
$md-min-width: 768px;
$lg-min-width: 1024px;
$xl-min-width: 1200px;
$xxl-min-width: 1920px;

$desktop-small-container-width: 900px;
$desktop-medium-container-width: 1100px;
$desktop-large-container-width: 1300px;

@mixin disclaimer() {
    @include type12;
    color: var(--cool-grey-700);
}

@mixin screen-reader-text() {
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal;
}

@mixin user-select-none() {
    user-select: none;
}

@mixin nowrap() {
    white-space: nowrap;
}

@mixin focus() {
    color: var(--primary-focus-theme);
}

@mixin page-padding-sm() {
    padding-left: 16px;
    padding-right: 16px;
}

@mixin page-padding-md() {
    padding-left: 24px;
    padding-right: 24px;
}

@mixin page-padding-lg() {
    padding-left: 60px;
    padding-right: 60px;
}

@mixin page-fullwidth() {
    margin: auto calc(-1 * (100vw - 100%)/2);
}

/* breakpoint media queries */

@mixin md {
    @media (min-width: $md-min-width) {
        @content;
    }
}

@mixin lg {
    @media (min-width: $lg-min-width) {
        @content;
    }
}

@mixin xl {
    @media (min-width: $xl-min-width) {
        @content;
    }
}

@mixin xxl {
    @media (min-width: $xxl-min-width) {
        @content;
    }
}

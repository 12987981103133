wdpr-media-engine {
    .me {
        .me-title-bar {
            // Hide the title on the ME
            // We add this title so not sure why we cannot just NOT add it
            // But that doesn't seem to work, so...
            .me-title {
                display: none;
            }
        }
    }
}

body.fullscreen {
    .fullscreen-constrained {
        wdpr-media-engine {
            .me.me-fullscreen {
                .me-slides {
                    .me-slides-media {
                        ul {
                            li {
                                video {
                                    object-fit: contain;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.me-slides-controls > .me-btn {
    color: var(--finder-primary-action);
}

.me-slides-controls > .me-btn:hover {
    color: var(--finder-primary-hover);
}

.me-slides-controls > .me-btn:focus {
    color: var(--finder-primary-focus);
}

.me-slides-controls > .me-btn:active {
    color: var(--finder-primary-active);
}

.me {
    .me-controls {
        .me-controls-video {
            .me-video-progress-bar::-webkit-slider-thumb {
                background: #FFF;
            }
            .me-video-progress-bar::-moz-range-thumb {
                background: #FFF;
            }
            .me-video-progress-bar::-ms-thumb {
                background: #FFF;
            }
            .me-video-play-pause {
                color: #FFF;
            }
            .me-video-volume {
                .me-btn {
                    color: #FFF;
                }

                .me-video-volume-mute {
                    &:hover {
                        color: #1994D7;
                    }
                }

                .me-video-volume-level {
                    border-color: #FFF;
                }
            }

            .me-video-timer {
                margin: 0;
            }
        }
    }

    &.me-no-touch {
        .me-thumbnails {
            .me-thumbnails-inner {
                .me-thumbnails-media {
                    ul {
                        li {
                            &:hover,
                            &:focus {
                                opacity: 1;
                            }
                            button {
                                &:active,
                                &:focus {
                                    border: 2px solid;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

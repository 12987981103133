@import './mixins/scaffolding-mixin';

.btn-group {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.btn-group wdpr-button {
    vertical-align: middle;
    --button-standard-size-max-width: 100%;
    --button-large-size-max-width: 100%;
    --button-mini-size-max-width: 100%;
    margin-bottom: 20px;
}

@include md {
    .btn-group {
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 33px;
        padding: 0;
    }

    .btn-group wdpr-button {
        margin-left: 20px;
        margin-bottom: 0;
        width: 200px;
    }
}

/* button styled as a link for a11y */
button.link {
    background: none !important;
    border: none;
    padding: 0 !important;
    font-family: var(--font-family-medium);
    color: var(--primary-text-theme);
    text-decoration: none;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

/* Link styles as a button */
.btn {
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;
    user-select: none;
    background-image: none;
    border: 2px solid;
    border-radius: 2px;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    height: 44px;
    line-height: 40px;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    width: 228px;
    transition: background 0.1s linear, border-color 0.1s linear;

    &.btn-primary {
        background-color: #169DBE;
        border-color: #169DBE;
        color: #FFF;
        &:not(.disabled) {
            &:hover {
                background-color: #253B56;
                border-color: #253B56;
                color: #FFF;
                text-decoration: none;
            }
        }
    }
}

@import './mixins/typography-mixin';
@import './mixins/scaffolding-mixin';

a,
a:hover,
a:focus {
    color: var(--primary-actionable-theme);
    cursor: pointer;
}

a {
    text-decoration: none;
}

a:hover,
a:focus {
    text-decoration: var(--primary-hover-theme);
}

a:focus:not(:active) {
    @include focus;
}

/* TODO: This is a massive update to the css rules on the page, 
affecting ALL <p> tags regardless of location, and needs to moved 
to a more targeted CSS selector / box model. ADS - 2024.09.13
* Added .magicbands rule below.  * Leaving this comment in place 
for training + review with fellow web team members.
hr {
    display: none; // We need to hide this since it is creating double horizontal lines in the page.
    height: 0;
    border: 0;
    border-bottom: 1px solid var(--cool-grey-300);
    margin: 2em 0;
    padding: 0;
}
*/

.magicbands hr {
    display: none; // We need to hide this since it is creating double horizontal lines in the page.
    height: 0;
    border: 0;
    border-bottom: 1px solid var(--cool-grey-300);
    margin: 2em 0;
    padding: 0;
}

[hidden] {
    display: none !important;
}

[unresolved] {
    display: none !important;
}

.container {
    @include page-padding-sm;
    margin: 30px auto;
    h1 {
        text-align: center;
    }
}

@include md {
    .container {
        @include page-padding-md;
    }
}

@include lg {
    .container {
        max-width: $lg-min-width;
        @include page-padding-lg;
    }
}

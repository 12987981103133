.avatar-for-magic-bands {
    background-image: url('/assets/images/error/dwarfs.png');
    background-position: bottom;
    background-repeat: no-repeat;
    height: 121px;
    width: 446px;
    margin-top: 50px;
    margin-bottom: 15px;
    margin-right: 25px;

    &.error-400 {
        background-image: url('/assets/images/error/fungus.png');
        height: 273px;
        width: 170px;
    }

    &.error-401 {
        background-image: url('/assets/images/error/army-soldier.png');
        height: 273px;
        width: 171px;
    }

    &.error-404 {
        background-image: url('/assets/images/error/stitch.jpg');
        height: 245px;
        width: 189px;
    }

    &.error-500 {
        background-image: url('/assets/images/error/donald.jpg');
        height: 244px;
        width: 219px;
    }

    &.error-502 {
        background-image: url('/assets/images/error/goofy.png');
        height: 230px;
        width: 118px;
    }

    &.error-503 {
        background-image: url('/assets/images/error/lady-tramp.png');
        background-size: contain;
        margin-right: 0;
        margin-top: 0;
        width: 269px;
    }

    &.error-504 {
        background-image: url('/assets/images/error/cogsworth.png');
        height: 268px;
        width: 193px;
    }
}
